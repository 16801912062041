const table = {
  dataInfo: [],
  columns: [
    {
      title: '图片',
      key: 'imgUrl',
      scopedSlots: { customRender: 'imgUrl' },
      width: 70
    },
    {
      title: '标题',
      dataIndex: 'title',
      key: 'title',
      width: 120
    },
    {
      title: 'H5链接',
      key: 'h5Url',
      dataIndex: 'h5Url',
      scopedSlots: { customRender: 'h5Url' },
      width: 650
    },
    {
      title: '创建人',
      dataIndex: 'createUser',
      key: 'createUser',
      width: 100
    },
    {
      title: '创建时间',
      dataIndex: 'createDateTime',
      key: 'createDateTime',
      width: 100
    },
    {
      title: '更新人',
      dataIndex: 'updateUser',
      key: 'updateUser',
      width: 100
    },
    {
      title: '更新时间',
      dataIndex: 'updateDateTime',
      key: 'updateDateTime',
      width: 100
    },
    {
      title: '操作',
      key: 'action',
      width: '200px',
      scopedSlots: { customRender: 'action' },
    },
  ],
  //分页数据
  pagination: {
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  },
};

export default {
  table
}